/* CSS Document for Joomla 3 styles */
/* ===========================
	FONT STYLES
	========================== */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans Condensed',sans-serif;
  font-weight: 700; }

h1 {
  font-size: 30px;
  line-height: 34px; }

h2 {
  font-size: 26px;
  line-height: 30px; }

h3 {
  font-size: 22px;
  line-height: 26px; }

h4 {
  font-size: 18px;
  line-height: 20px; }

h5 {
  font-size: 14px;
  line-height: 20px; }

h6 {
  font-size: 12px;
  line-height: 20px; }

/* ======================
	GENERAL HANDBALL STYLES
	===================== */
/* background */
body {
  background: #EEE;
  padding-top: 0; }

body > div.container {
  background: #FFF;
  padding: 10px 10px 30px;
  box-shadow: 0 0 10px #444;
  min-height: 900px; }

/* Side panels */
.well {
  background: transparent;
  border: 1px solid #eee;
  border-radius: 0;
  padding: 9px; }

/* Footer */
div.footer hr {
  margin: 10px 0px;
  border-width: 0px 0px; }

/* Header */
.bigimage {
  overflow: hidden;
  position: relative;
  /* sub */
  /* animate header */
  /*.intro-animation.left-side {
		-khtml-transition: left 0.5s cubic-bezier(0.1, -0.6, 0.2, 0);
		-moz-transition: left 0.5s cubic-bezier(0.1, -0.6, 0.2, 0);
		-ms-transition: left 0.5s cubic-bezier(0.1, -0.6, 0.2, 0);
		-o-transition: left 0.5s cubic-bezier(0.1, -0.6, 0.2, 0);
		-webkit-transition: left 0.5s cubic-bezier(0.1, -0.6, 0.2, 0);
		transition: left 0.5s cubic-bezier(0.1, -0.6, 0.2, 0);
	}*/
  /*#header-undertitle:hover{
		left: 200px;
	}*/
  /*#header-undertitle:hover{
		width: 200px;
		-khtml-transition: width 0.5s ease;
		-moz-transition: width 0.5s ease;
		-ms-transition: width 0.5s ease;
		-o-transition: width 0.5s ease;
		-webkit-transition: width 0.5s ease;
		transition: width 0.5s ease;
	}*/
  /*.intro-animation {
		left: -900px !important;
	}*/ }
  .bigimage .shear {
    -ms-transform: skewX(-28deg);
    /* IE 9 */
    -webkit-transform: skewX(-28deg);
    /* Safari */
    transform: skewX(-28deg);
    box-shadow: 0 5px 8px 5px rgba(0, 0, 0, 0.25);
    position: absolute; }
  .bigimage #header-title {
    height: 75%;
    width: 82%;
    background: none repeat scroll 0% 0% #04a;
    top: 8%;
    left: -5%; }
  .bigimage #header-title-text {
    color: #fff;
    font-size: 4em;
    font-weight: bold;
    position: absolute;
    top: 27%;
    left: 20%; }
  .bigimage #header-undertitle {
    height: 15%;
    width: 68%;
    background: none repeat scroll 0% 0% #fff;
    top: 57%;
    left: -5%; }
  .bigimage #header-undertitle-text {
    color: #000;
    font-size: 1.45em;
    font-weight: bold;
    font-style: italic;
    position: absolute;
    top: 60%;
    left: 19%; }
  .bigimage #header-logobox {
    height: 88%;
    width: 19%;
    background-color: #3771c8;
    left: -4%;
    bottom: 7%;
    /* load hover image in cache */
    background-size: 0px 0px;
    background-image: url("../images/logo-tsv_glow.png"); }
  .bigimage #header-logo {
    height: 73%;
    width: 13%;
    background: url("../images/logo-tsv.png") no-repeat scroll 0% 0% transparent;
    background-size: 100%;
    position: absolute;
    left: 3.75%;
    top: 11%; }
  .bigimage #header-linkbar {
    height: 38%;
    width: 33.5%;
    background: #3771c8;
    right: -2%;
    top: 13%;
    /* load hover image in cache */
    background-size: 0px 0px;
    background-image: url("../images/pictograms_65px_glow.png"); }
  .bigimage .pictogram {
    background: transparent;
    position: absolute;
    right: 2%;
    top: 15%; }
    .bigimage .pictogram li {
      position: relative;
      float: left;
      list-style: outside none none; }
    .bigimage .pictogram li a {
      display: block;
      background-image: url("../images/pictograms_65px.png"); }
    .bigimage .pictogram li:hover a {
      background-image: url("../images/pictograms_65px_glow.png"); }
    .bigimage .pictogram li a:active,
    .bigimage .pictogram li a:focus {
      outline: none; }
  .bigimage #header-logo a span,
  .bigimage .pictogram li a span {
    position: absolute;
    top: -9999px;
    left: -9999px; }
  .bigimage #header-subpage {
    height: 28%;
    width: 25%;
    background: none repeat scroll 0% 0% #04a;
    background: #3771c8;
    position: absolute;
    right: -5%;
    bottom: 7%; }
  .bigimage.sub #header-logo a {
    display: block; }
  .bigimage.sub #header-logo:hover {
    background-image: url("../images/logo-tsv_glow.png"); }
  .bigimage.sub #header-logo a:active,
  .bigimage.sub #header-logo a:focus {
    outline: none; }
  .bigimage.sub .pictogram {
    background: transparent;
    position: absolute; }
  .bigimage.sub .pictogram li a {
    background-image: url("../images/pictograms_65px.png"); }
  .bigimage.sub .pictogram li:hover a {
    background-image: url("../images/pictograms_65px_glow.png"); }
  .bigimage.sub #header-subtitle {
    background: none repeat scroll 0% 0% #04a;
    background: #fff;
    position: absolute; }
  .bigimage.sub #header-subtitle-text {
    color: #000;
    font-weight: bold;
    font-style: italic;
    position: absolute; }
  .bigimage.sub #header-subpage {
    background: none repeat scroll 0% 0% #04a;
    background: #3771c8;
    position: absolute; }
  .bigimage.sub #header-subpage-pictogram {
    background: url("../images/pictograms_130px.png") no-repeat scroll 0% 0% transparent;
    position: absolute; }
  .bigimage .pre-intro.left-side {
    left: -1200px !important; }
  .bigimage .pre-intro.right-side {
    right: -1200px !important; }
  .bigimage .intro-animation.left-side {
    -khtml-transition: left 0.5s ease-out;
    -moz-transition: left 0.5s ease-out;
    -ms-transition: left 0.5s ease-out;
    -o-transition: left 0.5s ease-out;
    -webkit-transition: left 0.5s ease-out;
    transition: left 0.5s ease-out; }
  .bigimage .intro-animation.right-side {
    -khtml-transition: right 0.5s ease-out;
    -moz-transition: right 0.5s ease-out;
    -ms-transition: right 0.5s ease-out;
    -o-transition: right 0.5s ease-out;
    -webkit-transition: right 0.5s ease-out;
    transition: right 0.5s ease-out; }

.breadcrumb {
  background-color: transparent; }

/* navbar overwrites */
.navbar-inner {
  background-color: #04a;
  background-image: none;
  background-repeat: no-repeat;
  border: none;
  border-radius: 0;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5); }

.navbar .nav > li > a {
  color: #fff;
  text-shadow: none; }

.navbar .nav > li > a:focus,
.navbar .nav > li > a:hover {
  color: #04a;
  background-color: #fff; }

.navbar .nav > .active > a,
.navbar .nav > .active > a:hover,
.navbar .nav > .active > a:focus
.nav li.dropdown.open > .dropdown-toggle,
.navbar .nav li.dropdown.active > .dropdown-toggle,
.navbar .nav li.dropdown.open.active > .dropdown-toggle {
  color: #fff;
  background-color: #3771c8;
  box-shadow: none; }

.navbar .nav li.dropdown > .dropdown-toggle .caret {
  display: none; }

.navbar ul.dropdown-menu {
  border-radius: 0;
  border: none;
  border-top: none;
  padding-top: 0; }

.navbar ul.dropdown-menu:before,
.navbar ul.dropdown-menu:after {
  display: none !important; }

ul.dropdown-menu {
  padding: 0; }

ul.dropdown-menu.small {
  font-size: inherit; }

.dropdown-submenu > .dropdown-menu {
  margin-top: 0; }

.nav-tabs {
  border-bottom: none; }

.nav-tabs > .active > a, .nav-tabs > .active > a:hover, .nav-tabs > .active > a:focus {
  background-color: #FFF;
  border: none; }

.nav-tabs > li > a {
  border: none;
  border-radius: 0; }

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: #FFF;
  background-color: #3771c8;
  background-image: none;
  background-repeat: no-repeat; }

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus, .dropdown-submenu:hover > a, .dropdown-submenu:focus > a {
  color: #FFF;
  background-color: #04a;
  background-image: none;
  background-repeat: no-repeat; }

/* responsive */
@media (max-width: 1200px) {
  div.header-inner {
    padding-top: 20px;
    background-position: 203px 5px;
    background-size: 300px; }
  div.container-content {
    background-position: 203px -180px;
    background-size: 300px; } }

@media (min-width: 980px) {
  /* header */
  .bigimage {
    height: 161px;
    font-size: 11px;
    /* sub */ }
    .bigimage .shear {
      box-shadow: 0 3px 5px 3px rgba(0, 0, 0, 0.25); }
    .bigimage #header-undertitle-text {
      top: 59%; }
    .bigimage .pictogram li a {
      height: 50px;
      width: 50px;
      background-size: 250px 50px;
      background-image: url("../images/pictograms_65px.png"); }
    .bigimage .pictogram li#pictogram-tu a {
      background-position: 0px 0; }
    .bigimage .pictogram li#pictogram-fb a {
      background-position: -50px 0; }
    .bigimage .pictogram li#pictogram-hb a {
      background-position: -100px 0; }
    .bigimage .pictogram li#pictogram-la a {
      background-position: -150px 0; }
    .bigimage .pictogram li#pictogram-vb a {
      background-position: -200px 0; }
    .bigimage.sub #header-subpage {
      height: 130px;
      width: 175px;
      right: -40px;
      top: 15px; }
    .bigimage.sub #header-subtitle {
      height: 40px;
      right: -20px;
      width: 330px;
      top: 98px; }
    .bigimage.sub.la #header-subtitle {
      width: 400px; }
    .bigimage.sub.la #header-subtitle-text {
      font-size: 26px;
      right: 180px; }
    .bigimage.sub.vb #header-subtitle {
      width: 340px; }
    .bigimage.sub #header-subtitle-text {
      color: #000;
      font-size: 25px;
      right: 170px;
      top: 108px; }
    .bigimage.sub #header-subpage-pictogram {
      height: 120px;
      width: 120px;
      right: 5px;
      top: 20px;
      background-size: 600px 120px; }
    .bigimage.tu #header-subpage-pictogram {
      background-position: 0px 0; }
    .bigimage.fb #header-subpage-pictogram {
      background-position: -120px 0; }
    .bigimage.hb #header-subpage-pictogram {
      background-position: -240px 0; }
    .bigimage.la #header-subpage-pictogram {
      background-position: -360px 0; }
    .bigimage.vb #header-subpage-pictogram {
      background-position: -480px 0; }
    .bigimage.sub #header-linkbar {
      height: 45px;
      width: 320px; }
    .bigimage.sub .pictogram {
      height: 40px;
      width: 300px; }
    .bigimage.sub .pictogram li a {
      height: 40px;
      width: 40px;
      background-size: 200px 40px;
      background-image: url("../images/pictograms_65px.png"); }
    .bigimage.sub .pictogram li#pictogram-tu a {
      background-position: 0px 0; }
    .bigimage.sub .pictogram li#pictogram-fb a {
      background-position: -40px 0; }
    .bigimage.sub .pictogram li#pictogram-hb a {
      background-position: -80px 0; }
    .bigimage.sub .pictogram li#pictogram-la a {
      background-position: -120px 0; }
    .bigimage.sub .pictogram li#pictogram-vb a {
      background-position: -160px 0; }
    .bigimage.sub.la #header-subtitle {
      width: 400px; }
    .bigimage.sub.la #header-subtitle-text {
      font-size: 26px;
      right: 180px; }
    .bigimage.sub.vb #header-subtitle {
      width: 340px; } }

@media (min-width: 1200px) {
  /* header */
  .bigimage {
    height: 200px;
    font-size: 14px;
    /* sub */ }
    .bigimage .shear {
      box-shadow: 0 5px 8px 5px rgba(0, 0, 0, 0.25); }
    .bigimage #header-undertitle-text {
      top: 60%; }
    .bigimage .pictogram li a {
      height: 65px;
      width: 65px;
      background-size: 325px 65px;
      background-image: url("../images/pictograms_65px.png"); }
    .bigimage .pictogram li#pictogram-tu a {
      background-position: 0px 0; }
    .bigimage .pictogram li#pictogram-fb a {
      background-position: -65px 0; }
    .bigimage .pictogram li#pictogram-hb a {
      background-position: -130px 0; }
    .bigimage .pictogram li#pictogram-la a {
      background-position: -195px 0; }
    .bigimage .pictogram li#pictogram-vb a {
      background-position: -260px 0; }
    .bigimage.sub #header-logo a {
      height: 135px;
      width: 135px; }
    .bigimage.sub #header-linkbar {
      height: 58px;
      width: 345px;
      top: 27px;
      right: -20px;
      top: 27px; }
    .bigimage.sub .pictogram {
      height: 50px;
      width: 300px;
      right: 40px;
      top: 31px; }
    .bigimage.sub .pictogram li a {
      height: 50px;
      width: 50px;
      background-size: 250px 50px; }
    .bigimage.sub .pictogram li#pictogram-tu a {
      background-position: 0px 0; }
    .bigimage.sub .pictogram li#pictogram-fb a {
      background-position: -50px 0; }
    .bigimage.sub .pictogram li#pictogram-hb a {
      background-position: -100px 0; }
    .bigimage.sub .pictogram li#pictogram-la a {
      background-position: -150px 0; }
    .bigimage.sub .pictogram li#pictogram-vb a {
      background-position: -200px 0; }
    .bigimage.sub #header-subtitle {
      height: 50px;
      width: 380px;
      right: -20px;
      top: 127px; }
    .bigimage.sub #header-subtitle-text {
      font-size: 30px;
      right: 190px;
      top: 140px; }
    .bigimage.sub #header-subpage {
      height: 138px;
      width: 195px;
      right: -50px;
      top: 50px; }
    .bigimage.sub #header-subpage-pictogram {
      height: 130px;
      width: 130px;
      right: 2px;
      top: 55px;
      background-size: 650px 130px; }
    .bigimage.tu #header-subpage-pictogram {
      background-position: 0px 0; }
    .bigimage.fb #header-subpage-pictogram {
      background-position: -130px 0; }
    .bigimage.hb #header-subpage-pictogram {
      background-position: -260px 0; }
    .bigimage.la #header-subpage-pictogram {
      background-position: -390px 0; }
    .bigimage.vb #header-subpage-pictogram {
      background-position: -520px 0; }
    .bigimage.sub.la #header-subtitle {
      width: 400px; }
    .bigimage.sub.la #header-subtitle-text {
      font-size: 26px;
      right: 180px; }
    .bigimage.sub.vb #header-subtitle {
      width: 400px; } }

@media (max-width: 979px) {
  div.header-inner {
    padding-top: 20px;
    background-position: 170px 5px;
    background-size: 231px; }
  div.container-content {
    background-position: 170px -142px;
    background-size: 231px; }
  /* header */
  .bigimage {
    height: 124px;
    font-size: 8.7px;
    /* sub */ }
    .bigimage .shear {
      box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.25); }
    .bigimage #header-undertitle-text {
      top: 57%; }
    .bigimage .pictogram li a {
      height: 40px;
      width: 40px;
      background-size: 200px 40px; }
    .bigimage .pictogram li#pictogram-tu a {
      background-position: 0px 0; }
    .bigimage .pictogram li#pictogram-fb a {
      background-position: -40px 0; }
    .bigimage .pictogram li#pictogram-hb a {
      background-position: -80px 0; }
    .bigimage .pictogram li#pictogram-la a {
      background-position: -120px 0; }
    .bigimage .pictogram li#pictogram-vb a {
      background-position: -160px 0; }
    .bigimage.sub #header-subpage {
      height: 95px;
      width: 120px;
      right: -30px;
      top: 15px; }
    .bigimage.sub #header-subtitle {
      height: 30px;
      width: 240px;
      right: -15px;
      top: 75px; }
    .bigimage.sub #header-subtitle-text {
      font-size: 20px;
      right: 115px;
      top: 80px; }
    .bigimage.sub #header-subpage-pictogram {
      height: 80px;
      width: 80px;
      background-size: 400px 80px;
      right: 0;
      top: 25px; }
    .bigimage.tu #header-subpage-pictogram {
      background-position: 0px 0; }
    .bigimage.fb #header-subpage-pictogram {
      background-position: -80px 0; }
    .bigimage.hb #header-subpage-pictogram {
      background-position: -160px 0; }
    .bigimage.la #header-subpage-pictogram {
      background-position: -240px 0; }
    .bigimage.vb #header-subpage-pictogram {
      background-position: -320px 0; }
    .bigimage.sub #header-linkbar {
      height: 38px;
      width: 250px;
      top: 24px; }
    .bigimage.sub .pictogram {
      height: 40px;
      width: 240px;
      top: 27px; }
    .bigimage.sub .pictogram li a {
      height: 35px;
      width: 35px;
      background-size: 175px 35px; }
    .bigimage.sub .pictogram li#pictogram-tu a {
      background-position: 0px 0; }
    .bigimage.sub .pictogram li#pictogram-fb a {
      background-position: -35px 0; }
    .bigimage.sub .pictogram li#pictogram-hb a {
      background-position: -70px 0; }
    .bigimage.sub .pictogram li#pictogram-la a {
      background-position: -105px 0; }
    .bigimage.sub .pictogram li#pictogram-vb a {
      background-position: -140px 0; }
    .bigimage.sub.la #header-subtitle {
      width: 400px; }
    .bigimage.sub.la #header-subtitle-text {
      font-size: 26px;
      right: 180px; }
    .bigimage.sub.vb #header-subtitle {
      width: 250px; } }

@media (max-width: 767px) {
  body {
    padding-right: 0px;
    padding-left: 0px; }
  div.header-inner {
    padding-top: 5px;
    background-position: 30% 5px;
    background-size: 30% auto; }
  div.container-content {
    background-position: 170px -142px;
    background-size: 231px;
    background-image: none; }
  /* header */
  .bigimage {
    height: 140px;
    font-size: 8.7px;
    /* sub */ }
    .bigimage .shear {
      box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.25); }
    .bigimage #header-title {
      height: 100px;
      min-width: 440px;
      max-width: 80%;
      left: -40px;
      top: 10px; }
    .bigimage #header-title-text {
      top: 35px;
      left: 122px;
      font-size: 2.75em; }
    .bigimage #header-undertitle {
      height: 20px;
      width: 390px;
      top: 70px;
      left: -30px; }
    .bigimage #header-undertitle-text {
      top: 70px;
      left: 110px;
      font-size: 1em; }
    .bigimage #header-logobox {
      height: 110px;
      width: 140px;
      left: -50px;
      bottom: 10px; }
    .bigimage #header-logo {
      height: 90px;
      width: 100px;
      left: 5px;
      top: auto;
      bottom: 22px; }
    .bigimage #header-linkbar {
      height: 42px;
      width: 230px;
      right: -15px;
      top: auto;
      bottom: 10px; }
    .bigimage .pictogram {
      right: 5px;
      top: auto;
      bottom: 11px; }
    .bigimage .pictogram li a {
      height: 40px;
      width: 39px;
      background-size: 200px 40px; }
    .bigimage .pictogram li#pictogram-tu a {
      background-position: 0px 0; }
    .bigimage .pictogram li#pictogram-fb a {
      background-position: -40px 0; }
    .bigimage .pictogram li#pictogram-hb a {
      background-position: -80px 0; }
    .bigimage .pictogram li#pictogram-la a {
      background-position: -120px 0; }
    .bigimage .pictogram li#pictogram-vb a {
      background-position: -160px 0; }
    .bigimage #header-subpage {
      height: 40px;
      width: 18%;
      right: -5%;
      top: 15%; }
    .bigimage.sub #header-subpage {
      height: 80px;
      width: 20%;
      right: -6%;
      top: 19%; }
    .bigimage.sub #header-subtitle {
      height: 30px;
      width: 260px;
      right: -20px;
      top: 98px; }
    .bigimage.sub #header-subtitle-text {
      font-size: 20px;
      right: 125px;
      top: 103px; }
    .bigimage.sub.la #header-subtitle {
      width: 400px; }
    .bigimage.sub.la #header-subtitle-text {
      font-size: 26px;
      right: 180px; }
    .bigimage.sub.vb #header-subtitle {
      width: 265px; }
    .bigimage.sub #header-subpage {
      height: 80px;
      width: 115px;
      right: -30px;
      top: 50px; }
    .bigimage.sub #header-subpage-pictogram {
      height: 70px;
      width: 70px;
      background-size: 350px 70px;
      right: 0;
      top: 55px; }
    .bigimage.tu #header-subpage-pictogram {
      background-position: 0px 0; }
    .bigimage.fb #header-subpage-pictogram {
      background-position: -70px 0; }
    .bigimage.hb #header-subpage-pictogram {
      background-position: -140px 0; }
    .bigimage.la #header-subpage-pictogram {
      background-position: -210px 0; }
    .bigimage.vb #header-subpage-pictogram {
      background-position: -280px 0; }
    .bigimage.sub #header-linkbar {
      height: 38px;
      width: 145px;
      top: 14px; }
    .bigimage.sub .pictogram {
      height: 40px;
      width: 150px;
      top: 17px;
      right: -3px; }
    .bigimage.sub .pictogram li a {
      height: 30px;
      width: 30px;
      background-size: 150px 30px; }
    .bigimage.sub .pictogram li#pictogram-tu a {
      background-position: 0px 0; }
    .bigimage.sub .pictogram li#pictogram-fb a {
      background-position: -30px 0; }
    .bigimage.sub .pictogram li#pictogram-hb a {
      background-position: -60px 0; }
    .bigimage.sub .pictogram li#pictogram-la a {
      background-position: -90px 0; }
    .bigimage.sub .pictogram li#pictogram-vb a {
      background-position: -120px 0; } }

@media (max-width: 480px) {
  .bigimage {
    height: 140px;
    /* sub */ }
    .bigimage #header-logobox {
      height: 70px;
      width: 80px;
      left: -20px;
      top: 15px; }
    .bigimage #header-logo {
      height: 60px;
      width: 80px;
      left: 5px;
      top: 20px;
      background-size: 60px; }
    .bigimage #header-title {
      height: 85px;
      width: 80%;
      min-width: 300px;
      top: 10px;
      left: -35px; }
    .bigimage #header-title-text {
      top: 50px;
      left: 70px;
      font-size: 20px; }
    .bigimage #header-undertitle {
      height: 17px;
      width: 295px;
      top: 17px; }
    .bigimage #header-undertitle-text {
      width: 250px;
      top: 15px;
      left: 20px;
      font-size: 1em; }
    .bigimage #header-subpage {
      height: 40px;
      width: 18%;
      right: -10%;
      top: 35px; }
    .bigimage.sub {
      height: 110px; }
    .bigimage.sub #header-title-text {
      top: 46px;
      left: 72px;
      font-size: 16px; }
    .bigimage.sub #header-undertitle {
      display: none; }
    .bigimage.sub #header-undertitle-text {
      display: none; }
    .bigimage.sub #header-subtitle {
      height: 25px;
      width: 190px;
      right: -20px;
      top: 75px; }
    .bigimage.sub.tu #header-subtitle {
      width: 180px; }
    .bigimage.sub.fb #header-subtitle {
      width: 180px; }
    .bigimage.sub.hb #header-subtitle {
      width: 195px; }
    .bigimage.sub.la #header-subtitle {
      width: 215px; }
    .bigimage.sub #header-subtitle-text {
      font-size: 15px;
      right: 85px;
      top: 76px; }
    .bigimage.sub.la #header-subtitle-text {
      right: 75px; }
    .bigimage.sub #header-subpage {
      height: 55px;
      width: 70px;
      right: -15px;
      top: 38px; }
    .bigimage.sub #header-subpage-pictogram {
      height: 50px;
      width: 50px;
      background-size: 250px 50px;
      right: -3px;
      top: 40px; }
    .bigimage.tu #header-subpage-pictogram {
      background-position: 0px 0; }
    .bigimage.fb #header-subpage-pictogram {
      background-position: -50px 0; }
    .bigimage.hb #header-subpage-pictogram {
      background-position: -100px 0; }
    .bigimage.la #header-subpage-pictogram {
      background-position: -150px 0; }
    .bigimage.vb #header-subpage-pictogram {
      background-position: -200px 0; }
    .bigimage.sub #header-linkbar {
      height: 32px;
      width: 150px;
      top: 2px; }
    .bigimage.sub .pictogram {
      height: 35px;
      width: 150px;
      top: 2px; }
    .bigimage.sub .pictogram li a {
      height: 30px;
      width: 30px;
      background-size: 150px 30px; }
    .bigimage.sub .pictogram li#pictogram-tu a {
      background-position: 0px 0; }
    .bigimage.sub .pictogram li#pictogram-fb a {
      background-position: -30px 0; }
    .bigimage.sub .pictogram li#pictogram-hb a {
      background-position: -60px 0; }
    .bigimage.sub .pictogram li#pictogram-la a {
      background-position: -90px 0; }
    .bigimage.sub .pictogram li#pictogram-vb a {
      background-position: -120px 0; } }

/* Links */
a, a:hover, a:focus {
  color: #0044CC; }

/* TODO content */
div#content div.items-leading div.leading-0,
div#content div.items-row div.item {
  padding: 0em 1em 1em;
  margin-bottom: 2em;
  /*border-top: 1px solid #bbb;
	border-bottom: 1px solid #bbb;*/ }

/* ===================================================================================
	BOOTSTRAP MEDIA QUERIES OVERRIDES
	NOTE: The order of the media queries is important when styles overwrite each other
	================================================================================== 
@media (min-width: 980px) {}
@media (min-width: 1200px) {}
@media (max-width: 979px) {}
@media (min-width: 768px) and (max-width: 979px) {}
@media (max-width: 767px) {}
@media (max-width: 480px) {}
*/
/* ======================
	LOGIN MODULE
	===================== */
/* ===================== */
.blog-featured {
  overflow: hidden; }
  .blog-featured h4 a {
    color: inherit; }
  .blog-featured h4 a:hover {
    color: #04a; }
  .blog-featured > div {
    padding: 1em; }
  .blog-featured div.shearbox-header {
    background: #04a;
    padding: 0.4em 1em 0.4em 0;
    margin-bottom: 1.5em;
    position: relative;
    left: -50px;
    transform: skewX(-28deg);
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.25); }
  .blog-featured div h2 {
    margin: 0 0 0 50px;
    color: #fff;
    transform: skewX(28deg); }
  .blog-featured div h2 a {
    color: #fff; }
  .blog-featured .shearbox-info {
    margin-top: 2em;
    background: #fff;
    position: relative;
    right: -50px;
    transform: skewX(-28deg);
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.25); }
  .blog-featured .article-info {
    transform: skewX(28deg);
    margin-left: 2em;
    margin-right: 22px;
    padding: 0.2em;
    border-right: 1px solid #ddd; }

/* ===================== */
.blog-category {
  overflow: hidden; }
  .blog-category h4 a {
    color: inherit; }
  .blog-category h4 a:hover {
    color: #04a; }
  .blog-category .item-content {
    min-height: 20rem; }
  .blog-category .item-text {
    margin-left: 300px;
    margin-right: 4rem;
    padding: 1rem; }
  .blog-category .item-image {
    width: 300px; }
    .blog-category .item-image img {
      width: 90%; }
  .blog-category div.shearbox-header {
    background: #04a;
    padding: 0.4em 1em 0.4em 0;
    margin-bottom: 1.5em;
    position: relative;
    left: -50px;
    transform: skewX(-28deg);
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.25); }
  .blog-category div h2 {
    margin: 0 0 0 50px;
    color: #fff;
    transform: skewX(28deg); }
  .blog-category div h2 a {
    color: #fff; }
  .blog-category .shearbox-info {
    margin-top: 2em;
    background: #fff;
    position: relative;
    right: -50px;
    transform: skewX(-28deg);
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.25); }
  .blog-category .article-info {
    transform: skewX(28deg);
    margin-left: 2em;
    margin-right: 22px;
    padding: 0.2em;
    border-right: 1px solid #ddd; }
  @media (max-width: 979px) {
    .blog-category .item-text {
      margin-left: 0;
      margin-right: 0;
      margin-top: 2rem;
      padding: 1rem; }
    .blog-category .item-image {
      width: 100%; }
      .blog-category .item-image img {
        width: 90%;
        padding-bottom: 2rem; } }
